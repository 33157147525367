<template>
    <div class="neituiAdmin">
        <div class="container flex">
            <div class="tree">
                <div class="tree-name">组织架构</div>
                <a-tree :selected-keys="selectedKeys" :tree-data="treeData" :replace-fields="treeReplaceFields" @select="_treeSelect"/>
            </div>
            <div class="content">
                <div style="padding-left: 25px;">
                    <a-tabs v-model="tabs" @change="_cutTabs()">
                        <a-tab-pane v-for="(item,index) in tabsList" :key="index" style="margin-left: 15px;">
                            <div slot="tab">
                                <div class="tab-num">{{item.num}}</div>
                                <div class="tab-name">{{item.name}}</div>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
                <div class="table">
                    <div class="search-div">
                        <a-tag closable @close="_tabClose(index)" v-for="(item,index) in searchList[tabs]" :key="item.name" style="margin-bottom: 5px;padding: 3px 5px">
                            <span>{{item.name}}</span>：
                            <span  v-if="item.name=='推荐人' || item.name=='候选人'">
                                <span v-for="(tab,index) in item.value">{{index==0?'':'&'}}{{tab.title}}</span>
                            </span>
                        </a-tag>
                    </div>
                    <!--参与内推-->
                    <a-table :columns="tableColumns[tabs]" :data-source="tableData" v-if="tabs == 0" :rowKey="'UserId'" :customRow="_clickRow" :pagination="false">
                        <div slot="code" slot-scope="text, record,index">
                            {{index+1}}
                        </div>
                        <div slot="nameTitle">
                            <span>推荐人姓名 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('person',$event,'','推荐人')"></i></span>
                        </div>
                        <div slot="operation" slot-scope="text, record,index" style="color: #2994FF;cursor: pointer" @click.stop="_grantMoney(index,'')">发放奖励</div>
                    </a-table>

                    <!--推荐简历-->
                    <a-table :columns="tableColumns[tabs]" :data-source="tableData" v-if="tabs != 0" :rowKey="tabs==2||tabs==3?'ResumeId':'UserId'" :customRow="_clickRow" :pagination="false">
                        <div slot="code" slot-scope="text, record,index">
                            {{index + 1 + (pageNum - 1) * 10}}
                        </div>
                        <div slot="nameTitle">
                            <span>候选人姓名 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('person',$event,'','候选人')"></i></span>
                        </div>
                        <div slot="operation" v-if="tabs==4" slot-scope="text, record,index" style="color: #2994FF;cursor: pointer" @click.stop="_grantMoney(index,'')">发放奖励</div>
                    </a-table>
                    <div style="margin-top: 20px;text-align: right">
                        <a-pagination :total="pageTotal" @change="_pageChange"/>
                    </div>
                </div>
            </div>
        </div>
        <!--详情-->
        <a-drawer :title="detailTitle + '推荐详情'" :closable="false" placement="right" :visible="detailVisible" width="1100" @close="_detailClose()" :bodyStyle="{padding:0}">
            <div style="padding-bottom: 80px;">
                <div style="padding-left: 20px;">
                    <a-tabs v-model="detailTabs" @change="_detailCutTabs()">
                        <a-tab-pane :key="1" style="margin-left: 15px;">
                            <div slot="tab">
                                <div class="tab-num">{{detailPageHeader.RecommendNum}}</div>
                                <div class="tab-name">推荐简历</div>
                            </div>
                        </a-tab-pane>

                        <a-tab-pane :key="2" style="margin-left: 15px;">
                            <div slot="tab">
                                <div class="tab-num">{{detailPageHeader.EmployedNum}}</div>
                                <div class="tab-name">录用人数</div>
                            </div>
                        </a-tab-pane>

                        <a-tab-pane :key="3" style="margin-left: 15px;">
                            <div slot="tab">
                                <div class="tab-num">{{detailPageHeader.EntryNum}}</div>
                                <div class="tab-name">入职人数</div>
                            </div>
                        </a-tab-pane>

                        <a-tab-pane :key="4" style="margin-left: 15px;">
                            <div slot="tab">
                                <div class="tab-num">{{detailPageHeader.CorrectNum}}</div>
                                <div class="tab-name">转正人数</div>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
                <div style="padding: 10px 20px;">
                    <a-table :columns="tableColumns[detailTabs]" :data-source="detailTableData" :rowKey="'Name'" :pagination="false">
                        <div slot="code" slot-scope="text, record,index">
                            {{index+1}}
                        </div>
                        <div slot="nameTitle">
                            <span>候选人姓名 <i class="iconfont icon-shaixuan filter-icon" @click="_showMOdelTest('person',$event,'','推荐人')"></i></span>
                        </div>
                        <div slot="operation" v-if="detailTabs==4" slot-scope="text, record,index" style="color: #2994FF;cursor: pointer" @click.stop="_grantMoney(index,'detail')">发放奖励</div>
                    </a-table>
                    <div style="margin-top: 20px;text-align: right">
                        <a-pagination :total="detailPageTotal" @change="_detailPageChange"/>
                    </div>
                </div>
            </div>

            <div class="alert-footer">
                <a-button style="margin-right: 25px" @click="_detailClose()">取消</a-button>
                <a-button type="primary"  @click="detailVisible = false">确定</a-button>
            </div>
        </a-drawer>

        <!--发放奖励-->
        <a-drawer title="发放奖励" :closable="false" placement="right" @close="awardVisible = false" :visible="awardVisible" :zIndex="1200" width="600">
            <div class="row-dev">
                <span class="row-babel">奖励获得者</span>
                <span class="row-input">{{setData.UserName || setData.RecommendUserName}}</span>
            </div>

            <div v-if="setType != 'detail'">
                <div class="row-dev">
                    <span class="row-babel">已选择候选人</span>
                    <span class="row-input" style="width: 350px">
                        <a-select style="width: 100%;" v-model="selectCandidate" :dropdownStyle="{zIndex: 1500}">
                            <a-select-option :value="index" v-for="(item,index) in selectCandidateList">{{item.Name}}</a-select-option>
                        </a-select>
                    </span>
                </div>

                <div class="row-dev" v-if="selectCandidateList.length!=0">
                    <span class="row-babel">未发放积分</span>
                    <span class="row-input">{{selectCandidateList[selectCandidate].NoPay}}</span>
                </div>

                <div class="row-dev" v-if="selectCandidateList.length!=0">
                    <span class="row-babel">发放积分 <span style="color: #FF0000">*</span></span>
                    <span class="row-input">
                        <a-input-number placeholder="输入积分不得大于未发放积分" style="width: 350px" :max="selectCandidateList[selectCandidate].NoPay" :min="0" v-model="setMoney"></a-input-number>
                    </span>
                </div>
            </div>

            <div v-if="setType == 'detail' || tabs==4">
                <div class="row-dev">
                    <span class="row-babel">未发放积分</span>
                    <span class="row-input">{{setData.NoPay}}</span>
                </div>

                <div class="row-dev" >
                    <span class="row-babel">发放积分 <span style="color: #FF0000">*</span></span>
                    <span class="row-input">
                        <a-input-number placeholder="输入积分不得大于未发放积分" style="width: 350px" :max="setData.NoPay" :min="0" v-model="setMoney"></a-input-number>
                    </span>
                </div>
            </div>

            <div class="row-dev" style="align-items: end">
                <span class="row-babel">发放备注</span>
                <div class="row-input">
                    <textarea v-model="setRemark"></textarea>
                </div>
            </div>

            <div class="row-dev" style="align-items: end">
                <span class="row-babel">发放凭证</span>
                <div class="row-input">
                    <img v-for="item in voucherList" :src="item.FileUrl" alt="">
                    <div class="upload-btn">
                        <a-icon type="plus" style="font-size: 30px"/>
                        <input type="file" @change="_uploadImg($event)" multiple>
                    </div>
                </div>
            </div>

            <div class="alert-footer">
                <a-button style="margin-right: 25px" @click="_closeGrant()">取消</a-button>
                <a-button type="primary"  @click="_saveGrant()">确定</a-button>
            </div>
        </a-drawer>

        <tableHeader :showMOdelTest="showMOdelTest" @getData="_tableGetData"></tableHeader>
    </div>
</template>

<script>
    import tableHeader from '../../components/tableHeader/filterBox';
    import person from "../../components/SelectPersonnel/SelectPersonnel"; //选人
    export default {
        name: "neituiAdmin",
        components:{
            tableHeader,person
        },
        data(){
            return {
                tabsList: '',
                treeReplaceFields:{
                    key: 'value'
                },
                userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')),
                detailVisible: false,
                awardVisible: false,
                selectedKeys: [],
                treeData: [],
                tableColumns: [
                    [
                        {
                            title: '序号',
                            align: 'center',
                            scopedSlots: {customRender:'code'}
                        },
                        {
                            dataIndex: 'UserName',
                            slots: { title: 'nameTitle' },
                            align: 'center',
                        },
                        {
                            title: '推荐人工号',
                            dataIndex: 'Num',
                            align: 'center',
                        },
                        {
                            title: '推荐人简历数',
                            dataIndex: 'RecNum',
                            align: 'center',
                        },
                        {
                            title: '录用人数',
                            dataIndex: 'EmployNum',
                            align: 'center',
                        },
                        {
                            title: '入职人数',
                            dataIndex: 'EntryNum',
                            align: 'center',
                        },
                        {
                            title: '转正人数',
                            dataIndex: 'FullNum',
                            align: 'center',
                        },
                        {
                            title: '奖励总积分',
                            dataIndex: 'TotalBonus',
                            align: 'center',
                        },
                        {
                            title: '未发放',
                            dataIndex: 'NoPay',
                            align: 'center',
                        },
                        {
                            title: '已发放',
                            dataIndex: 'Payed',
                            align: 'center',
                        },
                        {
                            title: '操作',
                            dataIndex: 'operation',
                            align: 'center',
                            scopedSlots: { customRender: 'operation' },
                        }
                    ],
                    [
                        {
                            title: '序号',
                            align: 'center',
                            scopedSlots: {customRender:'code'}
                        },
                        {
                            dataIndex: 'Name',
                            slots: { title: 'nameTitle' },
                            align: 'center',
                        },
                        {
                            title: '电话',
                            dataIndex: 'Mobile',
                            align: 'center',
                        },
                        {
                            title: '城市',
                            dataIndex: 'WorkPlace',
                            align: 'center',
                        },
                        {
                            title: '岗位',
                            dataIndex: 'Position',
                            align: 'center',
                        },
                        {
                            title: '招聘负责人',
                            dataIndex: 'HrOwnerName',
                            align: 'center',
                        },
                        {
                            title: '跟进状态',
                            dataIndex: 'Status',
                            align: 'center',
                        },
                        {
                            title: '推荐人姓名',
                            dataIndex: 'RecommendUserName',
                            align: 'center',
                        },
                        {
                            title: '推荐时间',
                            dataIndex: 'CreateTime',
                            align: 'center',
                        },
                        {
                            title: '内推奖励',
                            dataIndex: 'PrizeNum',
                            align: 'center',
                        },
                    ],
                    [
                        {
                            title: '序号',
                            align: 'center',
                            scopedSlots: {customRender:'code'}
                        },
                        {
                            dataIndex: 'Name',
                            slots: { title: 'nameTitle' },
                            align: 'center',
                        },
                        {
                            title: '区域',
                            dataIndex: 'ParentName',
                            align: 'center',
                        },
                        {
                            title: '岗位',
                            dataIndex: 'Position',
                            align: 'center',
                        },
                        {
                            title: '入职时间',
                            dataIndex: 'WorkDate',
                            align: 'center',
                        },
                        {
                            title: '招聘负责人',
                            dataIndex: 'HrOwnerName',
                            align: 'center',
                        },
                        {
                            title: '跟进状态',
                            dataIndex: 'Stage',
                            align: 'center',
                        },
                        {
                            title: '推荐人姓名',
                            dataIndex: 'RecommendUserName',
                            align: 'center',
                        },
                        {
                            title: '内推奖励',
                            dataIndex: 'PrizeNum',
                            align: 'center',
                        },
                        {
                            title: '奖励发放状态',
                            dataIndex: 'PrizeStatus',
                            align: 'center',
                        },
                    ],
                    [
                        {
                            title: '序号',
                            align: 'center',
                            scopedSlots: {customRender:'code'}
                        },
                        {
                            dataIndex: 'Name',
                            slots: { title: 'nameTitle' },
                            align: 'center',
                        },
                        {
                            title: '区域',
                            dataIndex: 'ParentName',
                            align: 'center',
                        },
                        {
                            title: '岗位',
                            dataIndex: 'Position',
                            align: 'center',
                        },
                        {
                            title: '入职时间',
                            dataIndex: 'WorkDate',
                            align: 'center',
                        },
                        {
                            title: '转正时间',
                            dataIndex: 'CorrectDate',
                            align: 'center',
                        },
                        {
                            title: '招聘负责人',
                            dataIndex: 'HrOwnerName',
                            align: 'center',
                        },
                        {
                            title: '跟进状态',
                            dataIndex: 'Stage',
                            align: 'center',
                        },
                        {
                            title: '推荐人姓名',
                            dataIndex: 'RecommendUserName',
                            align: 'center',
                        },
                        {
                            title: '内推奖励',
                            dataIndex: 'PrizeNum',
                            align: 'center',
                        },
                        {
                            title: '奖励发放状态',
                            dataIndex: 'PrizeStatus',
                            align: 'center',
                        },
                    ],
                    [
                        {
                            title: '序号',
                            align: 'center',
                            scopedSlots: {customRender:'code'}
                        },
                        {
                            dataIndex: 'Name',
                            slots: { title: 'nameTitle' },
                            align: 'center',
                        },
                        {
                            title: '区域',
                            dataIndex: 'ParentName',
                            align: 'center',
                        },
                        {
                            title: '面试岗位',
                            dataIndex: 'Position',
                            align: 'center',
                        },
                        {
                            title: '入职时间',
                            dataIndex: 'WorkDate',
                            align: 'center',
                        },
                        {
                            title: '转正时间',
                            dataIndex: 'CorrectDate',
                            align: 'center',
                        },
                        {
                            title: '招聘负责人',
                            dataIndex: 'HrOwnerName',
                            align: 'center',
                        },
                        {
                            title: '跟进状态',
                            dataIndex: 'Stage',
                            align: 'center',
                        },
                        {
                            title: '推荐人姓名',
                            dataIndex: 'RecommendUserName',
                            align: 'center',
                        },
                        {
                            title: '内推奖励',
                            dataIndex: 'PrizeNum',
                            align: 'center',
                        },
                        {
                            title: '奖励发放状态',
                            dataIndex: 'PrizeStatus',
                            align: 'center',
                        },
                        {
                            title: '操作',
                            dataIndex: 'operation',
                            align: 'center',
                            scopedSlots: { customRender: 'operation' },
                        }
                    ],
                ],
                tableData: [],
                showMOdelTest: {
                    show: false,
                    type: 'content',
                    init: ''
                },
                searchList: [[],[],[],[],[]],
                personObj: {
                    rank: 1, //职级 0为不选，1为选
                    nums: 10000, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "recent", //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                readPerson: '',
                detailTableData: [],
                detailPageHeader: '',
                tabs: 0,
                detailTabs: 1,
                detailTitle: '',
                setId: '',
                setData: '',
                setIndex: '',

                setMoney: '',
                setRemark: '',
                voucherList: [],
                pageTotal: 0,
                detailPageTotal: 0,
                pageNum: 1,
                detailPageNum: 1,
                selectCandidateList: [],
                selectCandidate: 0,
                setType: ''
            }
        },
        created(){
            this._infoData('');
            this._pageHeader('');
            this._treeData();
        },
        methods: {
            _saveGrant(){
                if(!this.setMoney){
                    return false;
                }
                this.$message.loading("加载中...");
                this.$axios.post(8000066,{
                    UserId: this.tabs== 0 && this.setType ==''? this.setData.UserId : this.setData.RecommendUserId,
                    UserName: this.tabs== 0 && this.setType ==''? this.setData.UserName : this.setData.RecommendUserName,
                    EmployeeList: this.tabs== 0 && this.setType ==''? this.selectCandidateList[this.selectCandidate].ResumeId : this.setData.ResumeId,
                    Amount: this.setMoney,
                    Remark: this.setRemark,
                    Voucher: JSON.stringify(this.voucherList)
                },res=>{
                    this.$message.destroy();
                    if(res.data.code==1){
                        this.$message.success(res.data.msg);
                        this._closeGrant();
                    }else{
                        this.$message.error(res.data.msg)
                    }
                })
            },
            _closeGrant(){
                this.setMoney = '';
                this.setRemark = '';
                this.voucherList = [];
                this.awardVisible = false;
                this.setData = '';
                this.setIndex = '';
                this.setType = '';
                this.selectCandidateList = [];
                this.selectCandidate = 0;

            },
            _uploadImg(e){
                this.__uploadFile__(e.target.files).then(res=>{
                    this.voucherList = this.voucherList.concat(res);
                })
            },
            _grantMoney(i,type){
                this.awardVisible = true;
                if(type=='detail'){
                    this.setData = this.detailTableData[i];
                }else{
                    this.setData = this.tableData[i];
                    this.$axios.get(8000077,{UserId:this.setData.UserId},res=>{
                        if(res.data.code==1){
                            this.selectCandidateList = res.data.data;
                            console.log(this.selectCandidateList)
                        }else{
                            this.selectCandidateList = [];
                        }
                    })
                }
                this.setType = type;
                console.log(this.setData);
                this.setIndex = i;
            },
            _infoData(id){
                this.$message.loading("加载中...");
                this.$axios.get(8000062,{
                    Type: id ? this.detailTabs : this.tabs,
                    Page: id ? this.detailPageNum : this.pageNum,
                    PageSize: 10,
                    PersonId: id,
                    PositionId: this.selectedKeys[0],
                    UserInfo: this.tabs==1 || this.tabs==2 || this.searchList[this.tabs].length==0 ? '' : JSON.stringify(this.searchList[this.tabs][0].value),
                },res=>{
                    this.$message.destroy();
                    if(id){
                        this.detailTableData = res.data.data;
                        this.detailPageTotal = res.data.count;
                    }else{
                        this.tableData = res.data.data;
                        this.pageTotal = res.data.count;
                    }
                })
            },
            _pageHeader(id){
                this.$axios.get(8000063,{
                    PersonId: id
                },res=>{
                    if(id){
                        this.detailPageHeader = res.data.data;
                    }else{
                        this.tabsList = [
                            {
                                num: res.data.data.JoinNum,
                                name: '参与内推'
                            },
                            {
                                num: res.data.data.RecommendNum,
                                name: '推荐简历'
                            },
                            {
                                num: res.data.data.EmployedNum,
                                name: '录用人数'
                            },
                            {
                                num: res.data.data.EntryNum,
                                name: '入职人数'
                            },
                            {
                                num: res.data.data.CorrectNum,
                                name: '转正人数'
                            }
                        ];
                    }
                })
            },
            _treeData(){
                this.$axios.get(8000064,{},res=>{
                    this.treeData = res.data.data;
                    this.treeData.unshift({title: '全部',value: ''})
                })
            },
            _treeSelect(selectedKeys){
                this.selectedKeys = selectedKeys;
                this._infoData('');
            },
            _pageChange(e){
                this.pageNum = e;
                this._infoData()
            },
            _detailPageChange(e){
                this.detailPageNum = e;
                this._infoData(this.setId);
            },
            _detailClose(){
                this.detailTableData = [];
                this.detailPageHeader =  [];
                this.setId = '';
                this.detailVisible = false;
            },
            _showAwardVisible(){
                this.awardVisible = true;
            },
            readChangePerson(value) {
                this.readPerson = value;
            },
            _clickRow(record, index){
                let self = this;
                return {
                    on: {
                        click: () => {
                            if(this.tabs == 0){
                                self.setId = record.UserId;
                                self.detailVisible = true;
                                self._infoData(record.UserId);
                                self._pageHeader(record.UserId);
                                self.detailTitle = record.UserName;
                            }
                        }
                    }
                }
            },
            _tabClose(i){
                this.searchList[this.tabs].splice(i,1);
                this._infoData('');
            },
            _cutTabs(){
                this.pageNum = 1;
                this.tableData = [];
                this._infoData();
            },
            _detailCutTabs(e){
                this._infoData(this.setId);
            },
            _showMOdelTest(type,e,list,txt){
                this.selectType = txt;
                let init = '';
                this.searchList[this.tabs].map(res=>{
                    if(res.name==txt){
                        init = res.value;
                    }
                });
                let data = {
                    list: list,
                    type: type,
                    show: true,
                    init: init,
                    event: e
                };
                this.showMOdelTest = data;
            },
            _tableGetData(val){
                let isNew = true;
                this.searchList[this.tabs].map(res=>{
                    if(res.name==this.selectType){
                        res.value = val;
                        isNew = false;
                    }
                });
                if(isNew){
                    this.searchList[this.tabs].push({name: this.selectType,value: val})
                }
                this._infoData('');
            },
        },
    }
</script>

<style scoped lang="less">
    .neituiAdmin{
        height: 100%;
        border-radius: 5px;
        .container{
            align-items: end;
            height: 100%;
        }
        .tree{
            width: 220px;
            margin-right: 10px;
            padding: 10px 5px 0;
            border-radius: 5px;
            min-height: 100%;
            background: #fff;
            .tree-name{
                padding: 0 5px 0;
            }
        }
        .content{
            flex: 1;
            min-height: 100%;
            background: #fff;
            border-radius: 5px;
            .table{
                width: 98%;
                margin: 0 auto;
                .filter-icon{
                    margin-left: 5px;
                    color: #BDBDBD;
                    font-size: 14px;
                }
                .search-div{
                    margin-bottom: 10px;
                }
            }
        }
    }
    .tab-num{
        text-align: center;
        font-size: 22px;
        color: #242424;
    }
    .tab-name{
        font-size: 14px;
        color: #999999;
    }
    .row-dev{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .row-babel{
            width: 100px;
        }
        .row-input{
            textarea{
                width: 350px;
                height: 150px;
                border: 1px solid #D0D3D8;
                resize: none;
                border-radius: 5px;
                outline: none;
                padding: 10px;
                font-size: 15px;
            }
            img{
                display: inline-block;
                vertical-align: middle;
                width: 60px;
                height: 60px;
                margin-right: 10px;
            }
            .upload-btn{
                position: relative;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                width: 60px;
                height: 60px;
                border: 1px solid #E8EAEC;
                i{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    font-size: 30px;
                    color: #E8EAEC;
                    cursor: pointer;
                }
                input{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
            }
        }
    }
    .alert-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button {
            width: 120px;
        }
    }
</style>
